<template>
  <esmp-select
    :placeholder="placeholder"
    multiple
    disabled
    filterable
    value=""
  />
</template>

<script>
export default {
  name: 'SearchView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    placeholder() {
      return this.settings.find((el) => el.techName === 'name').value || 'Поиск';
    },
  },
};
</script>
